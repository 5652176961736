import { useDisclosure } from '@mantine/hooks';
import { Link } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useClient } from 'urql';
import { InspectionCertificateSampleModal, InspectionCertificateScanModal } from '~/components/shared';
import { ImagePreviewFromFile } from '~/components/shared/ImagePreviewFromFile';
import { OwnedCarCarOriginEnum, OwnedCarDriveSystemEnum, SellingInputTypeEnum } from '~/gql/generated/graphql';
import { convertZipCodeQueryDocument } from '~/gql/queries';
import { useAnalytics, useAuth } from '~/hooks';
import { useDeliveryAddressContext } from '~/providers';
import styles from '~/styles/page/inquirePurchases/inquirePurchaseNew/inquire-purchase-form.module.css';
import { zipCodeRegex } from '~/utils';
import { japaneseYearText } from '~/utils/date';
import { AishameterValuesTable } from './AishameterValuesTable';
import { ColorModal } from './ColorModal';
import { useCarEstimateValuationForm } from './useCarEstimateValuationForm';

const blockClass = 'inquire-purchase-form';

export const PERIOD_MONTH_OPTIONS = [
{ label: '金額によってはすぐにでも', value: 1 },
{ label: '買い替え検討中or納車待ち', value: 3 },
{ label: 'なんとなく車の金額が気になっただけ', value: 6 },
{ label: 'その他', value: 0 }];

const PERIOD_MONTH_COMMENT_LABEL = {
  delivery_date: '納車日',
  other: 'その他'
};

export type Inputs = {
  lastName: string;
  firstName: string;
  zipCode: string;
  makeName?: string | null;
  carModelName?: string | null;
  grade?: string | null;
  driveSystem?: OwnedCarDriveSystemEnum | null;
  firstRegistrationYear?: number | string | null;
  mileageKm?: string | null;
  hasAccidentHistory: string | null;
  accidentComment: string | null;
  color: string | null;
  periodMonth: string | null;
  isDirty: string | null;
  hasOdor: string | null;
  comment: string | null;
  contentToken: string | null;
  sellingInputType: SellingInputTypeEnum;
  preferredSellingAmount: number | null;
  carType: OwnedCarCarOriginEnum | null;
  periodMonthComment: string;
};

type DefaultValues = {
  makeName?: string | null;
  carModelName?: string | null;
  grade?: string | null;
  driveSystem?: OwnedCarDriveSystemEnum | null;
  firstRegistrationYear?: number | null;
  mileageKm?: number | null;
  hasAccidentHistory?: boolean | null;
  accidentComment?: string | null;
  color?: string | null;
  periodMonth?: string | null;
  isDirty?: boolean | null;
  hasOdor?: boolean | null;
  comment?: string | null;
  preferredSellingAmount?: number | null;
  carType?: OwnedCarCarOriginEnum | null;
  periodMonthComment?: string | null;
  inspectionCertificateImageUrl?: string | null;
};

type Props = {
  onSubmit: (inputData: Inputs) => void;
  submitting: boolean;
  defaultValues?: DefaultValues;
  hiddenBar?: boolean;
  isFromAishameter?: boolean;
};

export const CarEstimateValuationForm: FunctionComponent<Props> = (props) => {
  const { onSubmit, submitting, defaultValues, hiddenBar, isFromAishameter } = props;
  const { fetching, user } = useAuth();
  const form = useForm<Inputs>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isDirty, errors }
  } = form;
  const watchZipCode = watch('zipCode');
  const watchColor = watch('color');
  const carType = watch('carType');
  const watchHasAccidentHistory = watch('hasAccidentHistory');
  const [address, setAddress] = useState<string>('');
  const client = useClient();
  const { zipCode: defaultZipCode } = useDeliveryAddressContext();
  const [previewImageFile, setPreviewImageFile] = useState<File | null>(null);
  const [defaultPreviewImageUrl, setDefaultPreviewImageUrl] = useState<string>();
  const watchPeriodMonth = watch('periodMonth');
  const periodMonthCommentLabel = getPeriodMonthCommentLabel(watchPeriodMonth);
  const [tab, setTab] = useState<SellingInputTypeEnum>(SellingInputTypeEnum.Inspection);
  const [colorModalOpened, { open: colorModalOpen, close: colorModalClose }] = useDisclosure(false);
  const [scanModalOpened, { open: scanModalOpen, close: scanModalClose }] = useDisclosure(false);
  const [sampleModalOpened, { open: sampleModalOpen, close: sampleModalClose }] = useDisclosure(false);
  const { sendTrackEvent } = useAnalytics();
  const { firstRegistrationYearChoices } = useCarEstimateValuationForm();

  useEffect(() => {
    if (!defaultValues) return;

    setValue('makeName', defaultValues.makeName || null);
    setValue('carModelName', defaultValues.carModelName || null);
    setValue('grade', defaultValues.grade || null);
    setValue('driveSystem', defaultValues.driveSystem || null);
    setValue('firstRegistrationYear', defaultValues.firstRegistrationYear || null);
    setValue('mileageKm', defaultValues.mileageKm?.toString() || null);
    setValue(
      'hasAccidentHistory',
      defaultValues.hasAccidentHistory === true ? '1' : defaultValues.hasAccidentHistory === false ? '0' : null
    );
    setValue('accidentComment', defaultValues.accidentComment || null);
    setValue('color', defaultValues.color || null);
    setValue('periodMonth', defaultValues.periodMonth || null);
    setValue('isDirty', defaultValues.isDirty === true ? '1' : defaultValues.isDirty === false ? '0' : null);
    setValue('hasOdor', defaultValues.hasOdor === true ? '1' : defaultValues.hasOdor === false ? '0' : null);
    setValue('comment', defaultValues.comment || null);
    setValue('preferredSellingAmount', defaultValues.preferredSellingAmount || null);
    setValue('carType', defaultValues.carType || null);
    setValue('periodMonthComment', defaultValues.periodMonthComment || '');

    if (defaultValues.inspectionCertificateImageUrl) {
      setDefaultPreviewImageUrl(defaultValues.inspectionCertificateImageUrl);
    }

    if (hiddenBar) {
      if (defaultValues.inspectionCertificateImageUrl) {
        setTab(SellingInputTypeEnum.Inspection);
      } else {
        setTab(SellingInputTypeEnum.Direct);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!watchZipCode?.match(zipCodeRegex)) {
        if (address) {
          setAddress('');
        }
        return;
      }
      const result = await client.query(convertZipCodeQueryDocument, { zipCode: watchZipCode });
      const resultAddress = result.data?.convertZipCode?.address || '';
      setAddress(resultAddress);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchZipCode]);

  useEffect(() => {
    if (fetching) {
      return;
    }

    setValue('zipCode', user?.zipCode || defaultZipCode || '');
  }, [setValue, defaultZipCode, fetching, user?.zipCode]);

  useEffect(() => {
    if (user) {
      setValue('lastName', user?.lastName || '');
      setValue('firstName', user?.firstName || '');
    }
  }, [user, setValue]);

  useEffect(() => {
    register('color', { required: true });
  }, [register]);

  useEffect(() => {
    register('contentToken', { required: !defaultPreviewImageUrl && tab === SellingInputTypeEnum.Inspection });
  }, [register, tab, defaultPreviewImageUrl]);

  useEffect(() => {
    setValue('sellingInputType', tab);
  }, [setValue, tab]);

  useEffect(() => {
    if (!isDirty) return;
    setValue('periodMonthComment', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, watchPeriodMonth]);

  const clickUploadCard = () => {
    sendTrackEvent('click_selling_form_open_camera');
    scanModalOpen();
  };

  const onSubmitForm: SubmitHandler<Inputs> = (inputData) => {
    const newInputData =
    tab === SellingInputTypeEnum.Inspection ?
    isFromAishameter ?
    {
      ...inputData,
      driveSystem: undefined
    } :
    {
      ...inputData,
      carModelName: undefined,
      firstRegistrationYear: undefined,
      grade: inputData.carType === OwnedCarCarOriginEnum.Imported ? inputData.grade : undefined,
      driveSystem: undefined
    } :
    { ...inputData, contentToken: null };
    onSubmit(newInputData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitForm)} className={styles[blockClass]}>
        {isFromAishameter && <AishameterValuesTable form={form} />}

        {/* 名前 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>名前</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div className={styles[`${blockClass}__group`]}>
              <input
                {...register('lastName', { required: true })}
                className={styles[`${blockClass}__input`]}
                placeholder='中野' />

              {errors.lastName && <p className={styles[`${blockClass}__error`]}>姓を入力してください</p>}
            </div>
            <div className={styles[`${blockClass}__group`]}>
              <input
                {...register('firstName', { required: true })}
                className={styles[`${blockClass}__input`]}
                placeholder='優作' />

              {errors.firstName && <p className={styles[`${blockClass}__error`]}>名を入力してください</p>}
            </div>
          </div>
        </div>

        {/* 郵便番号 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>郵便番号</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--center`]}`}>
            <div className={styles[`${blockClass}__group`]}>
              <input
                {...register('zipCode', { required: true, pattern: zipCodeRegex })}
                className={styles[`${blockClass}__input`]}
                placeholder='2700233' />

              {errors.zipCode && <p className={styles[`${blockClass}__error`]}>郵便番号を7桁で入力してください</p>}
            </div>
            <a
              href='https://www.post.japanpost.jp/zipcode'
              className={styles[`${blockClass}__link`]}
              target='_blank'
              rel='noopener noreferrer'>

              <p className={styles[`${blockClass}__link-text`]}>わからない方はこちら</p>
              <img src={'/images/icons/ic_other_page.svg'} alt='other_page' />
            </a>
          </div>
        </div>
        <div className={`${styles[`${blockClass}__item`]} `}>
          <p className={styles[`${blockClass}__label`]}>住所</p>
          {address ?
          <p className={styles[`${blockClass}__address-text`]}>{address}</p> :
          watchZipCode?.match(zipCodeRegex) ?
          <p className={styles[`${blockClass}__address-error`]}>郵便番号が正しくありません</p> :

          <p className={styles[`${blockClass}__address-error`]}>郵便番号を7桁で入力してください</p>
          }
        </div>

        <p className={styles[`${blockClass}__sub-title`]}>愛車情報</p>

        {!hiddenBar &&
        <>
            <p>
              車検証の写真をアップロードすると、より正確なお見積もりを提供できます。
              <br />
              個人情報は隠して撮影いただいて結構です。
            </p>

            <div className={styles[`${blockClass}__bar`]}>
              <div
              className={[
              styles[`${blockClass}__bar-tab`],
              styles[`${blockClass}__bar-tab--inspection`],
              tab === SellingInputTypeEnum.Inspection ? styles[`${blockClass}__bar-tab--active`] : ''].
              join(' ')}
              onClick={() => setTab(SellingInputTypeEnum.Inspection)}>

                <span>車検証を添付</span>
              </div>
              <div
              className={[
              styles[`${blockClass}__bar-tab`],
              styles[`${blockClass}__bar-tab--direct`],
              tab === SellingInputTypeEnum.Direct ? styles[`${blockClass}__bar-tab--active`] : ''].
              join(' ')}
              onClick={() => setTab(SellingInputTypeEnum.Direct)}>

                <span>車検証なし</span>
              </div>
            </div>
          </>
        }

        {/* 車の種類 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>車の種類</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div>
              <input
                {...register('carType', { required: true })}
                type='radio'
                id='carType-1'
                value={OwnedCarCarOriginEnum.Domestic} />

              <label htmlFor='carType-1'>国産車</label>
            </div>
            <div>
              <input
                {...register('carType', { required: true })}
                type='radio'
                id='carType-3'
                value={OwnedCarCarOriginEnum.Imported} />

              <label htmlFor='carType-3'>輸入車</label>
            </div>
          </div>
          {errors.carType && <p className={styles[`${blockClass}__error`]}>車の種類を選択してください</p>}
        </div>

        {/* グレード */}
        {tab === 'INSPECTION' && carType === OwnedCarCarOriginEnum.Imported &&
        <div className={styles[`${blockClass}__item`]}>
            <div className={styles[`${blockClass}__label`]}>
              <p className={styles[`${blockClass}__label-mark`]}>必須</p>
              <p className={styles[`${blockClass}__label-text`]}>グレード</p>
            </div>
            <input
            {...register('grade', { required: true })}
            className={styles[`${blockClass}__input`]}
            placeholder='EX' />

            {errors.grade && <p className={styles[`${blockClass}__error`]}>グレードを入力してください</p>}
          </div>
        }

        {tab === SellingInputTypeEnum.Inspection ? (
        /* 車検証 */
        <div className={styles[`${blockClass}__item`]}>
            <div className={styles[`${blockClass}__label-wrapper`]}>
              <div className={styles[`${blockClass}__label`]}>
                <p className={styles[`${blockClass}__label-mark`]}>必須</p>
                <p className={styles[`${blockClass}__label-text`]}>車検証</p>
              </div>
              <p className={styles[`${blockClass}__sample-link`]} onClick={sampleModalOpen}>
                サンプルを見る
              </p>
            </div>
            <div className={styles[`${blockClass}__upload`]} onClick={clickUploadCard}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/icons/ic_upload.svg' alt='upload' />
                <p className={styles[`${blockClass}__upload-text`]}>車検証の写真をアップロード</p>
              </div>
            </div>
            {(previewImageFile || defaultPreviewImageUrl) &&
          <div className={styles[`${blockClass}__upload-preview`]}>
                {previewImageFile ?
            <ImagePreviewFromFile imageFile={previewImageFile} /> :
            defaultPreviewImageUrl ?
            <img src={defaultPreviewImageUrl} alt='preview' /> :
            null}
              </div>
          }
            {errors.contentToken &&
          <p className={styles[`${blockClass}__error`]}>車検証の写真をアップロードしてください</p>
          }
          </div>) :

        <>
            {/* 車種 */}
            <div className={styles[`${blockClass}__item`]}>
              <div className={styles[`${blockClass}__label`]}>
                <p className={styles[`${blockClass}__label-mark`]}>必須</p>
                <p className={styles[`${blockClass}__label-text`]}>車種</p>
              </div>
              <input
              {...register('carModelName', { required: true })}
              className={styles[`${blockClass}__input`]}
              placeholder='NBOX' />

              {errors.carModelName && <p className={styles[`${blockClass}__error`]}>車種を入力してください</p>}
            </div>

            {/* グレード */}
            <div className={styles[`${blockClass}__item`]}>
              <div className={styles[`${blockClass}__label`]}>
                <p className={styles[`${blockClass}__label-mark`]}>必須</p>
                <p className={styles[`${blockClass}__label-text`]}>グレード</p>
              </div>
              <input
              {...register('grade', { required: true })}
              className={styles[`${blockClass}__input`]}
              placeholder='EX' />

              {errors.grade && <p className={styles[`${blockClass}__error`]}>グレードを入力してください</p>}
            </div>

            {/* 二駆・四駆 */}
            <div className={styles[`${blockClass}__item`]}>
              <div className={styles[`${blockClass}__label`]}>
                <p className={styles[`${blockClass}__label-mark`]}>必須</p>
                <p className={styles[`${blockClass}__label-text`]}>二駆・四駆</p>
              </div>
              <div className={styles[`${blockClass}__row`]}>
                <div>
                  <input
                  {...register('driveSystem', { required: true })}
                  type='radio'
                  id='two-wheel-drive'
                  value={OwnedCarDriveSystemEnum.TwoWheelDrive} />

                  <label htmlFor='two-wheel-drive'>二駆</label>
                </div>
                <div>
                  <input
                  {...register('driveSystem', { required: true })}
                  type='radio'
                  id='four-wheel-drive'
                  value={OwnedCarDriveSystemEnum.FourWheelDrive} />

                  <label htmlFor='four-wheel-drive'>四駆</label>
                </div>
              </div>
              {errors.driveSystem &&
            <p className={styles[`${blockClass}__error`]}>二駆・四駆どちらかを入力してください</p>
            }
            </div>

            {/* 初年度登録 */}
            <div className={styles[`${blockClass}__item`]}>
              <div className={styles[`${blockClass}__label`]}>
                <p className={styles[`${blockClass}__label-mark`]}>必須</p>
                <p className={styles[`${blockClass}__label-text`]}>初年度登録</p>
              </div>
              <select
              {...register('firstRegistrationYear', { required: true })}
              className={styles[`${blockClass}__input`]}>

                <option value=''>選択してください</option>
                {firstRegistrationYearChoices.map((year) =>
              <option key={year} value={year}>{`${year}（${japaneseYearText(year)}年）`}</option>
              )}
              </select>
              {errors.firstRegistrationYear && <p className={styles[`${blockClass}__error`]}>年式を入力してください</p>}
            </div>
          </>
        }

        {/* 走行距離 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>走行距離</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--end`]}`}>
            <input
              {...register('mileageKm', { required: true })}
              className={styles[`${blockClass}__input`]}
              placeholder='50000'
              type='number' />

            <p className={styles[`${blockClass}__input-unit`]}>km</p>
          </div>
          {isFromAishameter &&
          <p className={styles[`${blockClass}__note`]}>
              お手数ですが愛車メーターで入力した走行距離より詳細な値を入力してください
            </p>
          }
          {errors.mileageKm && <p className={styles[`${blockClass}__error`]}>走行距離を入力してください</p>}
        </div>

        {/* カラー */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>カラー</p>
          </div>
          <div className={styles[`${blockClass}__input`]} onClick={colorModalOpen}>
            {watchColor ? watchColor : <span style={{ color: '#d1d1d1' }}>選択してください</span>}
          </div>
          {errors.color && <p className={styles[`${blockClass}__error`]}>カラーを入力してください</p>}
        </div>

        {/* においの状態 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>においの状態（喫煙やペット乗車など）</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div>
              <input {...register('hasOdor', { required: true })} type='radio' id='not-has-odor' value={0} />
              <label htmlFor='not-has-odor'>なし</label>
            </div>
            <div>
              <input {...register('hasOdor', { required: true })} type='radio' id='has-odor' value={1} />
              <label htmlFor='has-odor'>あり</label>
            </div>
          </div>
          {errors.hasOdor && <p className={styles[`${blockClass}__error`]}>においの状態を入力してください</p>}
        </div>

        {/* 汚れの状態 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>汚れの状態（喫煙やペット乗車など）</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div>
              <input {...register('isDirty', { required: true })} type='radio' id='not-dirty' value={0} />
              <label htmlFor='not-dirty'>なし</label>
            </div>
            <div>
              <input {...register('isDirty', { required: true })} type='radio' id='dirty' value={1} />
              <label htmlFor='dirty'>あり</label>
            </div>
          </div>
          {errors.isDirty && <p className={styles[`${blockClass}__error`]}>汚れの状態を入力してください</p>}
        </div>

        {/* 事故歴(災害歴) */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>事故歴(災害歴)</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div>
              <input {...register('hasAccidentHistory', { required: true })} type='radio' id='repare-none' value={0} />
              <label htmlFor='repare-none'>なし</label>
            </div>
            <div>
              <input {...register('hasAccidentHistory', { required: true })} type='radio' id='has-repare' value={1} />
              <label htmlFor='has-repare'>あり</label>
            </div>
          </div>
          {errors.hasAccidentHistory &&
          <p className={styles[`${blockClass}__error`]}>事故歴(災害歴)を入力してください</p>
          }
          {watchHasAccidentHistory && Number(watchHasAccidentHistory) === 1 &&
          <textarea
            {...register('accidentComment')}
            className={styles[`${blockClass}__input`]}
            placeholder='事故の状況をご記入ください。'
            rows={4} />

          }
        </div>

        {/* 車両状態の詳細 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>車両状態の詳細</p>
          </div>
          <textarea
            {...register('comment')}
            className={styles[`${blockClass}__input`]}
            placeholder={
            'オプション機能（サンルーフ有り、ドラレコ付き）や内外装の状態について、特記すべき点があれば、お気軽にご記入ください。'
            }
            rows={4} />

        </div>

        {/* 売却時期 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>売却時期</p>
          </div>
          <div className={styles[`${blockClass}__item`]}>
            <div className={styles[`${blockClass}__sales-period`]}>
              {PERIOD_MONTH_OPTIONS.map((option) =>
              <div key={option.value}>
                  <input
                  {...register('periodMonth', { required: true })}
                  type='radio'
                  id={`period-${option.value}`}
                  value={option.value} />

                  <label htmlFor={`period-${option.value}`}>{option.label}</label>
                </div>
              )}
            </div>
          </div>
          {periodMonthCommentLabel &&
          <>
              <div className={styles[`${blockClass}__label`]}>
                <p
                className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(
                  ' '
                )}>

                  任意
                </p>
                <p className={styles[`${blockClass}__label-text`]}>
                  {PERIOD_MONTH_COMMENT_LABEL[periodMonthCommentLabel]}
                </p>
              </div>
              <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--end`]}`}>
                <input
                {...register('periodMonthComment')}
                className={styles[`${blockClass}__input`]}
                placeholder={
                periodMonthCommentLabel === 'delivery_date' ? '納車日が決まっている場合は入力ください' : ''
                } />

              </div>
            </>
          }
          {errors.periodMonth && <p className={styles[`${blockClass}__error`]}>売却次期を入力してください</p>}
        </div>

        {/* 売却希望額 */}
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>売却希望額</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--end`]}`}>
            <input {...register('preferredSellingAmount')} type='number' className={styles[`${blockClass}__input`]} />
            <p className={styles[`${blockClass}__input-unit`]}>万円</p>
          </div>
          <p className={styles[`${blockClass}__input-notice`]}>※桁数をご確認ください</p>
        </div>

        <p className={styles[`${blockClass}__terms`]}>
          「送信」をクリックすることで、当社の<Link to='/terms'>利用規約</Link>及び
          <Link to='/privacy'>プライバシーポリシー</Link>に同意したものとみなします。
        </p>
        <button type='submit' className={styles[`${blockClass}__button`]} disabled={submitting}>
          送信
        </button>
      </form>
      <ColorModal
        opened={colorModalOpened}
        defaultColor={watchColor}
        close={colorModalClose}
        onSelect={(color: string) => setValue('color', color, { shouldValidate: true })} />

      <InspectionCertificateScanModal
        opened={scanModalOpened}
        close={scanModalClose}
        onUploaded={({ contentToken, imageFile }) => {
          setValue('contentToken', contentToken);
          clearErrors('contentToken');
          setPreviewImageFile(imageFile);
          scanModalClose();
        }} />

      <InspectionCertificateSampleModal opened={sampleModalOpened} close={sampleModalClose} />
    </>);

};

const getPeriodMonthCommentLabel = (periodMonth: number | string | null) => {
  if (!periodMonth) return null;
  if (periodMonth.toString() === '3') return 'delivery_date';
  if (periodMonth.toString() === '0') return 'other';
  return null;
};