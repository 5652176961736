import { useSearchParams } from '@remix-run/react';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import styles from '~/styles/page/inquirePurchases/inquirePurchaseNew/aishameter-values-table.module.css';
import { japaneseYearText } from '~/utils/date';
import type { Inputs } from './CarEstimateValuationForm';
import { useCarEstimateValuationForm } from './useCarEstimateValuationForm';

type TableItemProps = {
  label: string;
  name: keyof Inputs;
  isEdit: boolean;
  form: UseFormReturn<Inputs, any, undefined>;
};
const TableItem: FunctionComponent<TableItemProps> = (props) => {
  const { label, name, isEdit, form } = props;
  const watchedValue = useWatch({ control: form.control, name });

  return (
    <div className={styles.item}>
      <p className={styles.label}>{label}</p>
      <div className={styles.value}>
        {isEdit ?
        <input
          className={styles.input}
          value={watchedValue ?? undefined}
          onChange={(e) => form.setValue(name, e.target.value)} /> :


        <p>{watchedValue}</p>
        }
      </div>
    </div>);

};

const FirstRegistrationYearTableItem: FunctionComponent<{
  isEdit: boolean;
  form: UseFormReturn<Inputs, any, undefined>;
}> = (props) => {
  const { isEdit, form } = props;
  const name = 'firstRegistrationYear';
  const watchedValue = useWatch({ control: form.control, name });
  const { firstRegistrationYearChoices } = useCarEstimateValuationForm();

  return (
    <div className={styles.item}>
      <p className={styles.label}>初度登録</p>
      <div className={styles.value}>
        {isEdit ?
        <select
          value={watchedValue ?? undefined}
          onChange={(e) => form.setValue(name, e.target.value)}
          className={styles.input}>

            <option value=''>選択してください</option>
            {firstRegistrationYearChoices.map((year) =>
          <option key={year} value={year}>{`${year}（${japaneseYearText(year)}年）`}</option>
          )}
          </select> :

        <p>{watchedValue}</p>
        }
      </div>
    </div>);

};

const MileageHistoryTableItem: FunctionComponent<{
  isEdit: boolean;
  form: UseFormReturn<Inputs, any, undefined>;
}> = (props) => {
  const { isEdit } = props;
  const [searchParams] = useSearchParams();
  const minMileageKm = searchParams.get('minMileageKm');
  const maxMileageKm = searchParams.get('maxMileageKm');

  return (
    <div className={styles.item}>
      <p className={styles.label}>走行距離</p>
      <div className={styles.value}>
        <p>{`${minMileageKm?.toLocaleString() || ''}〜${maxMileageKm?.toLocaleString() || ''}km`}</p>
        {isEdit && <p style={{ marginTop: 8 }}>お手数ですが下記のフォームでより詳細な値を入力してください</p>}
      </div>
    </div>);

};

type Props = {
  form: UseFormReturn<Inputs, any, undefined>;
};
export const AishameterValuesTable: FunctionComponent<Props> = (props) => {
  const { form } = props;
  const [isEdit, setIsEdit] = useState(false);
  const baseTableItemProps = { isEdit, form };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <p className={styles.title}>基本情報</p>
        {!isEdit &&
        <p className={styles['edit-link']} onClick={() => setIsEdit(true)}>
            修正する
          </p>
        }
      </div>
      <div className={styles.table}>
        <TableItem label='メーカー' name='makeName' {...baseTableItemProps} />
        <TableItem label='車種名' name='carModelName' {...baseTableItemProps} />
        <FirstRegistrationYearTableItem {...baseTableItemProps} />
        <TableItem label='グレード' name='grade' {...baseTableItemProps} />
        <MileageHistoryTableItem {...baseTableItemProps} />
      </div>
    </div>);

};