import { useState, type FunctionComponent } from 'react';
import { Modal } from '~/components/shared';
import styles from '~/styles/page/inquirePurchases/inquirePurchaseNew/color-modal.module.css';

type Props = {
  opened: boolean;
  defaultColor: string | null;
  close: () => void;
  onSelect: (color: string) => void;
};

export const ColorModal: FunctionComponent<Props> = (props) => {
  const { opened, defaultColor, close, onSelect } = props;
  const [selectedColor, setSelectedColor] = useState<string | null>(defaultColor);
  const colors = [
  { name: 'ホワイト', code: '#ffffff' },
  { name: 'パール', code: '#f4f2e4' },
  { name: 'ブラック', code: '#000000' },
  { name: 'グレー', code: '#9c9c9c' },
  { name: 'シルバー', code: '#f2f2f2' },
  { name: 'ブルー', code: '#3d4ff4' },
  { name: 'レッド', code: '#f43d3d' },
  { name: 'ブラウン', code: '#8b5617' },
  { name: 'ベージュ', code: '#e8e1d3' },
  { name: 'ゴールド', code: '#e5cc79' },
  { name: 'イエロー', code: '#f5d858' },
  { name: 'グリーン', code: '#53b73a' },
  { name: 'オレンジ', code: '#e77B2f' },
  { name: 'ピンク', code: '#e673aa' },
  { name: 'パープル', code: '#b124d4' },
  { name: 'その他', code: '#ffffff' }];


  const handelClickButton = () => {
    if (!selectedColor) return;

    onSelect(selectedColor);
    close();
  };

  return (
    <Modal
      isOpen={opened}
      className={styles.modal}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <div className={styles.close} onClick={close}>
            <img src={'/images/icons/ic_close.svg'} alt='close' />
          </div>
          <p className={styles.title}>愛車の色を選択してください</p>
          <div className={styles['color-list']}>
            {colors.map((color, index) =>
          <div key={index} className={styles['color-item']} onClick={() => setSelectedColor(color.name)}>
                <div
              className={[styles['color-block'], color.name === 'その他' ? styles['color-block-other'] : ''].join(
                ' '
              )}
              style={
              color.name === 'パール' ?
              { background: 'linear-gradient(180deg, #F4F2E4 0%, rgba(253, 248, 236, 0.00) 126.92%)' } :
              color.name === 'シルバー' ?
              { background: 'linear-gradient(180deg, #DDD 0%, #F2F2F2 100%)' } :
              color.name === 'ゴールド' ?
              { background: 'linear-gradient(180deg, #E5CC79 0%, rgba(213, 187, 99, 0.00) 130.77%)' } :
              { backgroundColor: color.code }
              } />

                <p className={styles['color-text']}>{color.name}</p>
                <div className={selectedColor === color.name ? styles['color-check'] : ''}>
                  <img src={'/images/icons/ic_check.svg'} alt='check' />
                </div>
              </div>
          )}
          </div>
          <button className={styles.button} onClick={handelClickButton}>
            OK
          </button>
        </>
      }
    </Modal>);

};