const FIRST_REGISTRATION_YEAR_CHOICE_MIN = 1975;

export const useCarEstimateValuationForm = () => {
  const currentYear = new Date().getFullYear();
  const firstRegistrationYearChoices = Array.from(
    { length: currentYear - FIRST_REGISTRATION_YEAR_CHOICE_MIN + 1 },
    (_, index) => currentYear - index
  );

  return { firstRegistrationYearChoices };
};
